import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import cpluspluslogo from '../assets/images/cplusplus_logo.svg'
import HeaderPHP from '../components/HeaderPHP'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import HeaderCPlusPlus from '../components/HeaderCPlusPlus'
import c1 from '../assets/images/c1.png'
import c2 from '../assets/images/c2.png'
import c3 from '../assets/images/c3.png'
import c4 from '../assets/images/c4.png'
import c5 from '../assets/images/c5.png'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Python vs C++ - Comparison - pythonvs.com" />
        <HeaderCPlusPlus />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What is C++?</h2>
                </header>
                <p>C++ is a programming language created all the way back in 1985. It was created as an extension of the programming language C. Its new versions are released every 3 years, and at the time of writing this, C++20 is underway.</p>

                <p>C++ is a popular programming language in the video game industry. For instance, the language was used to create World of Warcraft.</p>

                <p>It is, however, quite a difficult language to learn. </p>

                <p>Over the past years C++ has been fairly consistent in its place on the list of Top Languages Over Time according to GitHub's <a href="https://octoverse.github.com/">State of the Octoverse</a>. It's no. 6 on the list, behind such languages as e.g. Python, but ahead of TypeScript.</p>

              </div>
              <span className="image">
                <img src={cpluspluslogo} alt="Python vs C++" />
              </span>
            </div>
          </section>
          <section className="main">
            <header className="major">
              <h2>Python vs C++</h2>
            </header>
            <p>In a battle between an absolute computing classic, an interpreted C++, in the ring for 35 years, and a ten-years-younger Python, an object-oriented high-level language with growing popularity in most commercial segments, determining what&rsquo;s important for us is key. C++ offers speed and, with high skill of a developer, is often the most efficient solution to a given problem. Python, on the other hand, offers elegant and simple code, easily maintained and joyfully created. Both have die-hard fan bases, although C++ seems to be mostly popular amongst experienced programmers, while Python gets fans across the board. So what&rsquo;s better? We compare the most important aspects of these two legendary languages. This is <span>C++ vs. Python: the final throw down.</span></p>
            <h2>C++ vs. Python: in numbers</h2>
            <h3>TIOBE Index</h3>
            <p><span>The TIOBE Programming Community index is an indicator of the popularity of programming languages compiled monthly and based on the number of skilled engineers world-wide, courses and third party vendors using a given language. </span><span>You can read more about the index and the organization at: </span><a href="https://www.tiobe.com/" target="_blank" rel="noopener"><span>https://www.tiobe.com/</span></a></p>
            <img src={c1} alt="Python vs C++" />
            <br />
            <ul>
              <li>Python has been climbing the TOBIE rankings in recent years, jumping to the second place just in November 2020 and still rising at an impressive rate. C++, steadily holding 4th place, is also growing, although at a slightly slower rate.</li>
              <br />
              <img src={c4} alt="Python vs C++" />
              <br />
            </ul>
            <ul>
              <li>TIOBE indices over the years indicate that while C++ has been incredibly popular since the beginning of the ranking (consistently placing in top three between 1990 and 2015), Python&rsquo;s been treading behind and eventually surpassed it. Both are well-established, and we can expect their reign to last some more.&nbsp;</li>
            </ul>
            <ul>
              <li>Python has been TOBIE&rsquo;s language of the year three times, and was most recently crowned in 2018. C++ has been language of the year only once, back in 2003, the inaugural year of the ranking.&nbsp;</li>
            </ul>
            <h3>Stack Overflow</h3>
            <p><span>Stack Overflow&rsquo;s 2020 was the 9th consecutive year for the survey with 90,000 developers responding. </span><span>More information on SO&rsquo;s website: </span><a href="https://insights.stackoverflow.com/" target="_blank" rel="noopener"><span>https://insights.stackoverflow.com/</span></a></p>
            <br />
            <center>
            <img src={c5} alt="Python vs C++" /><br />
            <img src={c2} alt="Python vs C++" />
            </center>
            <br />
            <ul>
              <li>Python is roughly twice as popular as C++ when it comes to Stock Overflow&rsquo;s respondents. C++ is used by 1 in 5 professional developers and almost quarter of all respondents. Python on the other hand has surpassed 40% amongst professionals and 44% amongst all respondents and is continuously rising in popularity.&nbsp;</li>
            </ul>
            <h3>GitHub users</h3>
            <p>GitHub is one of the world&rsquo;s largest code development platforms. It is the largest host of source code in the world with more than 40 million users and over 100 million repositories (including at least 28 million public repositories). Ben Frederickson used GitHub&rsquo;s archives to track programming languages its users write code in. Every user interaction with a repository is counted as using the language of that repository. The aggregated number gives the Monthly Active Users (MAU) each language has. <span>More info on methodology and sources: </span><a href="https://www.benfrederickson.com/ranking-programming-languages-by-github-users/" target="_blank" rel="noopener"><span>https://www.benfrederickson.com/ranking-programming-languages-by-github-users/</span></a></p>
            
            <center><img src={c3} alt="Python vs C++" /></center>
            <br />
            <ul>
              <li>Python is more of a favourite amongst GitHub users, however both languages are steadily in top 5. Both Python and C++ also present slightly upward trends, so both can be considered equally safe in their market positions.</li>
            </ul>
            <h3>Other rankings comparing Python vs. C++ in numbers</h3>
            <ul>
              <li>Number of pull requests in a given language is used to rank programming languages in <a href="https://octoverse.github.com/" target="_blank" rel="noopener"><span>GitHub Octoverse</span></a></li>
              <li>How likely programmers are to switch a language was measured using blog posts discussing moving from one language to another in order to rank programming languages by <a href="http://r" target="_blank" rel="noopener">Erik Bernhardsson</a></li>
              <li>Number of people transitioning languages on GitHub was used by the team at <a href="https://blog.sourced.tech/post/language_migrations/" target="_blank" rel="noopener">sourced</a></li>
            </ul>
            <h2>Python vs. C++ from the managerial perspective&nbsp;</h2>
            <ul>
              <li>
                <h4>Python vs. C++: speed</h4>
              </li>
              <ul>
                <li>Points for C++</li>
                <li>Because C++ is a compiled language, it will perform much better than Python when it comes to speed.</li>
                <li>What&rsquo;s more, since C++ indicates the variable type, it will detect certain types of errors when the program is being built, before execution.&nbsp;</li>
                <li>Because of its superb efficiency and speed performance, C++ is known and used widely in applications that are required to run fast, such as gaming platforms.</li>
              </ul>
              <li>
                <h4>Cross-platform compatibility and portability</h4>
              </li>
              <ul>
                <li>Points for Python</li>
                <li>Because Python is an interpreted language that works on multiple platforms without any need to rewrite code, it is much more flexible in terms of cross-platform compatibility.&nbsp;</li>
                <li>Another aspect of this cross-platform support is in Python&rsquo;s extensive standard library, which is written to work on all operating systems (Linux, macOS, Windows).</li>
                <li>Issues with C++</li>
                <li>Oftentimes, portions of code need to be rewritten in order to work in different environments.&nbsp;</li>
                <li>What&rsquo;s more, C++ is not portable, meaning you need to recompile the code on each platform you want to use it on.&nbsp;</li>
              </ul>
              <li>
                <h4>Rapid prototyping</h4>
              </li>
              <ul>
                <li>Points for Python</li>
                <li>Because of its development time efficiency, Python is used widely with Minimum Viable Product (MVP) model and rapid building and testing of prototypes, even if the final product is to be written in a different language.&nbsp;</li>
                <li>Issues with C++</li>
                <li>C++ cannot be used to produce rapid prototypes due to larger code size.</li>
              </ul>
            </ul>
            <ul>
              <li>
                <h4>Applications</h4>
              </li>
              <ul>
                <li>Points for C++</li>
                <li>Nowadays, C++ is used to program embedded devices (so, hardware). Because it&rsquo;s much closer to machine language, it&rsquo;s more efficient in dealing with hardware programming.&nbsp;</li>
                <li>C++ is suitable for almost every platform including embedded systems.&nbsp;</li>
                <li>It&rsquo;s also mostly used for any code requiring efficient coding.&nbsp;</li>
                <li><span>Issues with Python</span></li>
                <li>Python can be used only on most platforms that support high-level languages, including the most popular operating systems such as Linux, Windows, and macOS, granted they are supported by the Python interpreter and have enough memory to run it.</li>
                <li>Python can also be difficult to install on Windows.</li>
              </ul>
            </ul>
            <h2>Python vs. C++ from developer perspective&nbsp;</h2>
            <p>Developers focus on different features when looking for a language to learn and develop in.</p>
            <ul>
              <li>
                <h4>Python vs. C++: syntax</h4>
              </li>
              <ul>
                <li>Points for Python</li>
                <li>Python is often considered to be one of the easiest programming languages to learn due to its simple and readable syntax.&nbsp;</li>
                <li>Issues with C++</li>
                <li>Because C++ is more of a lower-level language, it&rsquo;s easier for computers than humans.</li>
                <li>What&rsquo;s more, C++ requires more time and effort put in as the program gets more complex.&nbsp;</li>
              </ul>
              <li>
                <h4>Code efficiency and simplicity</h4>
              </li>
              <ul>
                <li>Points for Python</li>
                <li>Python enables developers to program functions more efficiently, using fewer lines of code in comparison to C++.</li>
                <li>Python&rsquo;s syntax is also much simpler and easier to understand by inexperienced developers. It&rsquo;s also much easier to maintain when deployed.&nbsp;</li>
                <li>Issues with C++</li>
                <li>C++ code is much more difficult to maintain as the code can get complicated to read as solutions become bigger.</li>
                <li>C++ also requires far more lines of codes to program the same thing.&nbsp;</li>
              </ul>
              <li>
                <h4>Garbage collection and memory management</h4>
              </li>
              <ul>
                <li>Issues with C++</li>
                <li>In C++, memory management is done manually. The language doesn&rsquo;t support garbage collection of resources.</li>
                <li><span>Points for Python</span></li>
                <li>Python, on the other hand, comes with garbage collection.</li>
              </ul>
              <li>
                <h4>Nature of the language&nbsp;</h4>
              </li>
              <ul>
                <li>Points for C++</li>
                <li>C++ is a statically typed language which means variables (with their data type) are declared and verified at compile time. This keeps the source code error-free at runtime.&nbsp;</li>
                <li>Issues with Python</li>
                <li>Python, on the other hand, is dynamically typed. There is no type checking done at compile time. Hence, Python code is more prone to errors.&nbsp;</li>
              </ul>
              <li>
                <h4>Community support</h4>
              </li>
              <ul>
                <li>Points for Python&nbsp;</li>
                <li>Python has a huge, active and very diverse community. This is very helpful for novice developers and there is a huge number of community-based libraries and other resources.</li>
                <li><span>Issues with C++</span></li>
                <li>The C++ community consists mostly of very experienced users who share advanced insights. There are very few resources for beginners in comparison to Python.&nbsp;</li>
              </ul>
            </ul>
            <h2>Python vs. C++: summary</h2>
            <p>C++ and Python are two distinctly different programming languages, both with very diverse features and applications. Python&rsquo;s simple syntax and high readability come with slower speeds of execution relative to C++. While the latter offers every feature imaginable in system programming, Python&rsquo;s best for machine learning development and rapid prototyping. When choosing between the two, one crucial question should come to mind. What&rsquo;s more important: speed of development or speed of execution? If it&rsquo;s the former, Python, with its robust community and rapid prototyping, takes the crown. If it&rsquo;s the latter, C++ compiled nature makes it a foremost choice for speed-sensitive apps, such as games.&nbsp;</p>
            <p><span>Python vs. C++: differences and uses</span></p>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Python</td>
                    <td>C++</td>
                  </tr>
                  <tr>
                    <td>Good for</td>
                    <td>Statistics, data analyticsMachine learningfintechData-heavy sites and servers with high-traffic volume</td>
                    <td>embedded systems (smartwatches, medical machines, Internet of Things sensors)3D, multiplayer, or other types of complex game developmentanything that requires high speed and efficiency in executionhardware programming</td>
                  </tr>
                  <tr>
                    <td>Bad for&nbsp;</td>
                    <td>Data processing&mdash;it is not designed to perform well in highly specialized apps for data processing</td>
                    <td>Web developmentRapid prototypingOperating systems development&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>


          <section id="second" className="main special">
            <header className="major">
              <h2>C++ in numbers</h2>
              <p>
                Statistics of C++ programming language based on <a href="https://www.tiobe.com/tiobe-index/">TIOBE index</a>, <a href="https://insights.stackoverflow.com/survey/2020">Stackoverflow Developer Survey 2020</a> and <a href="https://www.jetbrains.com/lp/devecosystem-2020/">The State of Developer Ecosystem 2020</a> by Jetbrains.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                Number
                <strong>4</strong> in TIOBE index
              </li>
              <li className="style2">
                Used by
                <strong>20,5%</strong>Developers
              </li>
              <li className="style3">
                Loved by
                <strong>43,4%</strong>Developers
              </li>
              <li className="style4"> Primary language
                <strong>13%</strong>Developers
              </li>
              <li className="style5">
                Want to learn
                <strong>8,2%</strong> Developers              </li>
            </ul>


          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>Need custom software development services?</h2>
              <p>

                Visit our site
                <br />
                to find out more about services we offer.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://www.stxnext.com/services/" className="button special">
                    Custom Software Development Services
                  </a>
                </li>
                <li>
                  <a href="https://www.stxnext.com/portfolio/" className="button">
                    View our Portfolio
                  </a>
                </li>
              </ul>
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
