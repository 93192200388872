import React from 'react'
import cpluspluslogo from '../assets/images/cplusplus_logo.svg';

const HeaderCPlusPlus = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={cpluspluslogo} alt="Python vs Java" /></span>
        <h1>Python vs C++</h1>
        <h2>Comparison of Python and C++.</h2>
    </header>
)

export default HeaderCPlusPlus
