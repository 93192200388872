import React from 'react'
import phplogo from '../assets/images/php_logo.svg';

const HeaderPHP = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={phplogo} alt="Python vs Java" /></span>
        <h1>Python vs PHP</h1>
        <h2>Comparison of Python and PHP.</h2>
    </header>
)

export default HeaderPHP
